<template>
  <div class="floating-label-input mt-2">
    <input
      type="number"
      min="1"
      max="10000"
      @change="
        () => {
          if (
            !internalValue.answer ||
            internalValue.answer > 10000 ||
            internalValue.answer < 1
          ) {
            internalValue.answer = 1;
          }
        }
      "
      v-model="internalValue.answer"
      class="peer focus:outline-none border-none p-0"
      :placeholder="internalValue.label"
    />
    <label for="number" class=""
      >{{ internalValue.label }}</label
    >
  </div>
</template>

<script >
export default {
  name: "FormNumber",
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
  },
};
</script>
