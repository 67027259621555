import apiClient from '@/services/axios'

export async function post(dto) {
    const url = "https://rekom-crm-functions-prod.azurewebsites.net/api/BookingFormSubmitted?code=qf1ztVNPVIWe8zr2tQ-Pm840Y55RQxn4zBcAahouYjTPAzFuixmctg=="
    console.log("Posting", dto);
    var response = await apiClient.post(url, dto);

    if (response) {
        console.log(response)
        return response.data;
    }

    throw new Error('Unable to post form')
}