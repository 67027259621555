<template>
  <div style="display: block" v-if="venueId && formId">
    <button @click="closeForm(false)" class="btn-popup-close hidden md:visible">
      <img src="../assets/cancel_btn.png" />
    </button>
    <!--      max-w-xs
        sm:max-w-sm
        md:max-w-screen-sm
        w-full
        mx-auto
        my-10
        px-2
        md:px-16 -->
    <div
      id="booking-form"
      class="sm:max-w-screen-sm md:max-w-screen-sm w-full mx-auto my-10 px-6 sm:px-16"
    >
      <Loading :text="$t('message.loading')" class="mb-16" v-if="!form" />
      <!--   <p class="opacity-60">Loading form</p>
        <span class="flex h-12 w-12 mx-auto mt-5">
          <span
            class="
              animate-ping
              absolute
              inline-flex
              h-12
              w-12
              rounded-full
              bg-primary
              opacity-75
            "
          ></span>
          <span
            class="relative inline-flex rounded-full h-12 w-12 bg-primary"
          ></span>
        </span> -->

      <div v-else>
        <div class="flex justify-between">
          <h1 class="page-header mb-2">{{ form.publicFormName }}</h1>
          <button
            @click="closeForm(false)"
            class="btn-popup-close-relative md:hidden"
          >
            <img src="../assets/cancel_btn.png" />
          </button>
        </div>

        <div class="mb-8 flex" v-if="venueName">
          <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 9.66662C9.38071 9.66662 10.5 8.54734 10.5 7.16663C10.5 5.78591 9.38071 4.66663 8 4.66663C6.61929 4.66663 5.5 5.78591 5.5 7.16663C5.5 8.54734 6.61929 9.66662 8 9.66662Z"
              stroke="black"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.7138 11.8808L9.17801 15.4167C8.8655 15.7289 8.44183 15.9042 8.00009 15.9042C7.55836 15.9042 7.13469 15.7289 6.82218 15.4167L3.28551 11.8808C2.3532 10.9485 1.71829 9.76058 1.46108 8.46739C1.20388 7.17419 1.33592 5.83376 1.84051 4.61561C2.34511 3.39745 3.19959 2.35628 4.29591 1.62376C5.39223 0.891229 6.68115 0.500244 7.99968 0.500244C9.31821 0.500244 10.6071 0.891229 11.7034 1.62376C12.7998 2.35628 13.6542 3.39745 14.1588 4.61561C14.6634 5.83376 14.7955 7.17419 14.5383 8.46739C14.2811 9.76058 13.6462 10.9485 12.7138 11.8808V11.8808Z"
              stroke="black"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="ml-1 text-lg" style="line-height: 1">
            {{ venueName }}
          </p>
        </div>

        <!-- Component binding -->
        <component
          v-for="(field, fIdx) in form.fields"
          :key="fIdx"
          v-model="form.fields[fIdx]"
          :is="'Form' + field.type"
          :currency="form.currency"
          :numGuests="numGuests"
          :class="{ 'mt-8': fIdx === 0 }"
        />

        <!-- Consent (Legal documents) -->
        <legal-document
          v-for="(docWrapper, idx) in form.legalDocuments"
          v-model="form.legalDocuments[idx]"
          :venueId="venueId"
          :key="idx"
        />

        <!-- Totals -->
        <div class="flex justify-center mb-5 mt-12" v-if="hasProducts">
          <p class="totals">
            {{ $t("message.total") }} {{ totalSpend }}
            {{ $t("message.currency." + form.currency) }}
            <span v-if="form.minimumSpend > 0"
              >/ {{ form.minimumSpend }}
              {{ $t("message.currency." + form.currency) + " " }}
            </span>
            <span class="vat">{{ $t("message.tax") }}</span>
          </p>
        </div>

        <!-- Submit button  -->
        <div
          class="flex justify-center pb-12"
          :class="{ 'mt-12': !hasProducts }"
        >
          <vue-recaptcha
            @verify="onVerify"
            @error="onCaptchaError"
            @expired="onCaptchaError"
            ref="recaptcha"
            sitekey="6LeXBncjAAAAAOyHXYUw-8SOgWVk0ojbhE9KQFWT"
            size="invisible"
          ></vue-recaptcha>
          <div class="flex flex-col items-center flex-grow">
            <div v-if="hasError" class="mb-4">
              <span style="color: red">{{ $t("message.errorMsg") }}</span>
            </div>
            <button
              @click="submit()"
              :disabled="!isValid || isSubmitting"
              class="btn-cot"
              :style="
                !isValid || isSubmitting ? 'opacity: 0.5; cursor: default' : ''
              "
            >
              {{ form.submitButtonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import { DatePicker } from "v-calendar";
import { ref } from "vue";
import { mapGetters } from "vuex";
import { ArrowNarrowRightIcon, CheckIcon } from "@heroicons/vue/solid";
import {
  DialogPanel,
  TransitionRoot,
  TransitionChild,
  Dialog,
  //DialogTitle,
} from "@headlessui/vue";
import "v-calendar/dist/style.css";

// Static components
import Loading from "@/components/Loading.vue";
import LegalDocument from "@/components/LegalDocument.vue";

// Dynamic components
import FormText from "@/components/FormComponents/FormText.vue";
import FormDropdown from "@/components/FormComponents/FormDropdown.vue";
import FormTextarea from "@/components/FormComponents/FormTextarea.vue";
import FormDate from "@/components/FormComponents/FormDate.vue";
import FormBirthdate from "@/components/FormComponents/FormBirthdate.vue";
import FormNumber from "@/components/FormComponents/FormNumber.vue";
import FormProductsSingle from "@/components/FormComponents/FormProductsSingle.vue";
import FormProducts from "@/components/FormComponents/FormProducts.vue";
import FormEmail from "@/components/FormComponents/FormEmail.vue";
import FormPhone from "@/components/FormComponents/FormPhone.vue";

export default {
  components: {
    VueRecaptcha,
    DatePicker,
    TransitionRoot,
    DialogPanel,
    TransitionChild,
    Dialog,
    ArrowNarrowRightIcon,
    CheckIcon,
    Loading,
    LegalDocument,
    FormText,
    FormDropdown,
    FormTextarea,
    FormDate,
    FormBirthdate,
    FormNumber,
    FormProductsSingle,
    FormProducts,
    FormEmail,
    FormPhone,
  },
  setup() {
    return {
      venueId: ref(""),
      formId: ref(""),
      /*       form: ref({
        fields: [],
        gaConversionValue: 500,
        minimumSpend: 1000,
        currency: "€",
        submitButtonText: "Submit",
        publicFormName: "Book et bord",
        legalDocuments: [
          {
            isAccepted: false,
            docId: "LEGALDOC-11C5",
            displayText: "I accept the terms and conditions",
            isRequired: true,
          },
        ],
      }),
      fields: ref([
        {
          id: "1",
          type: "Text",
          answer: "",
          label: "Your name here",
          isRequired: true,
        },
        {
          id: "2",
          type: "Email",
          answer: "",
          label: "Your e-mail here",
          isRequired: true,
        },
        {
          id: "3",
          type: "Phone",
          answer: "",
          countryCode: "",
          label: "Your phone here",
          isRequired: true,
        },
        {
          id: "4",
          type: "Dropdown",
          answer: "",
          availableOptions: ["38", "39", "40"],
          label: "Select dress size",
          isRequired: true,
        },
        {
          id: "5",
          type: "Textarea",
          answer: "",
          label: "Anytihng we should know?",
          isRequired: true,
        },
        {
          id: "6",
          type: "Date",
          answer: "",
          label: "Arrival date",
          isRequired: true,
        },
        {
          id: "7",
          type: "Number",
          answer: "",
          label: "How many guests?",
          isRequired: true,
        },
        {
          id: "8",
          type: "Products",
          products: [
            {
              quantity: 0,
              id: "1",
              name: "Product 1",
              price: 100,
              beforePrice: 200,
              imageUrl: "https://picsum.photos/300/300",
              description: "Lorem ipsum dolor sit amet, consectetur",
            },
            {
              quantity: 0,
              id: "2",
              name: "Product 2",
              price: 200,
              beforePrice: 300,
              imageUrl: "https://picsum.photos/300/300",
              description:
                "Nunc nisl aliquet nisl, eget aliquam nisl nisl sit amet lorem.",
            },
          ],
          label: "Tilføj drinks - Klar på dit bord når du ankommer",
          helpText:
            "Bestil en af vores vilde bordpakker til en god pris. Vil du selv designe din bordpakke? så skriv dine ønsker i en besked nederst på siden. Obs. minimumspend er 1500 kr.",
          isRequired: true,
        },
      ]), */
      isSubmitting: ref(false),
      hasError: ref(false),
    };
  },
  computed: {
    numGuests() {
      const field = this.form.fields.find(
        (e) => e.type === "Number" && e.technicalType == "NumGuests"
      );
      return field ? field.answer : 0;
    },
    isValid() {
      return (
        this.form.fields.every(
          (field) =>
            !field.isRequired || (field.answer !== null && field.answer !== "")
        ) &&
        this.form.legalDocuments.every(
          (doc) => !doc.isRequired || doc.isAccepted
        ) &&
        this.form.fields.every(
          (field) =>
            field.type !== "Email" ||
            (field.answer !== null && field.answer.includes("@"))
        ) &&
        (this.form.minimumSpend === 0 ||
          this.form.minimumSpend <= this.totalSpend)
      );

      // &&
    },
    ...mapGetters({
      // Consent
      form: "form/form",
      venueName: "legalDocuments/venueName",
      venueCountry: "legalDocuments/venueCountry",
      legalDocuments: "legalDocuments/legalDocuments",
      analyticsDetails: "legalDocuments/analyticsDetails",
    }),
    hasProducts() {
      return this.form.fields.some(
        (e) => e.type === "Products" || e.type === "ProductsSingle"
      );
    },
    totalSpend() {
      // TODO single product support
      if (!this.hasProducts) return 0;

      // compute total of all products fields
      const totalProducts = this.form.fields
        .filter((e) => e.type === "Products")
        .map((e) => e.products.map((p) => p.price * p.quantity))
        .flat()
        .reduce((partialSum, a) => partialSum + a, 0);

      const totalProductsSingle = this.form.fields
        .filter((e) => e.type === "ProductsSingle")
        .map((e) =>
          e.products.map((p) => (p.quantity > 0 ? p.price * this.numGuests : 0))
        )
        .flat()
        .reduce((partialSum, a) => partialSum + a, 0);

      return totalProducts + totalProductsSingle;

      /*    const priceSingle = 
        this.form.fields.filter((e) => e.type === "Products")[0]
        .products.map((e) => e.quantity * e.price)
        .reduce((a, b) => a + b, 0);
      const priceMulti = this.form.fields
        this.form.fields.filter((e) => e.type === "Products")[0]
        .products.map((e) => e.quantity * e.price)
        .reduce((a, b) => a + b, 0);
        return priceSingle + priceMulti; */
      // return 0;
    },
  },
  async mounted() {
    this.formId = this.$route.params.formId;
    this.venueId = this.$route.params.venueId;
    await this.$store.dispatch("form/GetForm", this.formId);
    console.log(
      "Loaded with form id",
      this.formId,
      "venue id",
      this.venueId,
      "form",
      this.form
    );

    window.parent.postMessage(
      JSON.stringify({
        action: "bookingForm_gtmTrackCustomEvent",
        eventData: {
          ga_event_name: "booking_form_opened",
          item_id: this.form.id,
          item_name: this.form.formName,
          event_category: "booking form",
          event_label: "Booking form opened",
          // idm_venueId: this.venueId,
          // idm_venueName: this.venueName,
          // country: this.venueCountry,
        },
      }),
      "*"
    );

    //this.searchVenue(shortName);
  },
  methods: {
    async onCaptchaError() {
      this.isSubmitting = false;
    },
    async onVerify(token) {
      console.log("Recaptcha verified");
      await this.submitAfterVerify(token);
    },
    closeForm() {
      // Submit tracking events
      try {
        const numFilledFields = this.form.fields.filter(
          (field) => field.answer !== null && field.answer !== ""
        ).length;

        window.parent.postMessage(
          JSON.stringify({
            action: "bookingForm_gtmTrackCustomEvent",
            eventData: {
              ga_event_name: "booking_form_closed",
              item_id: this.form.id,
              item_name: this.form.formName,
              event_category: "booking form",
              event_label: "Booking form closed",
              num_filled_fields: numFilledFields,
              // idm_venueId: this.venueId,
              // idm_venueName: this.venueName,
              // country: this.venueCountry,
            },
          }),
          "*"
        );
      } finally {
        // Posing closeBookingForm
        window.parent.postMessage(
          JSON.stringify({ action: "closeBookingForm" }),
          "*"
        );
      }
    },
    async submitAfterVerify(recaptchaToken) {
      // set product quantity to numGuests if produc type is productSingle
      this.form.fields
        .filter((e) => e.type === "ProductsSingle")
        .forEach((e) => {
          e.products.forEach((p) => {
            p.quantity = p.quantity > 0 ? this.numGuests : 0;
          });
        });

      const dto = {
        bookingForm: this.form,
        recaptchaToken: recaptchaToken,
        venueId: this.venueId,
      };

      console.log("Submitting form", dto);

      try {
        await this.$store.dispatch("form/SubmitForm", dto);
      } catch (e) {
        console.error("Error submitting form", e);
        this.hasError = true;
        this.isSubmitting = false;
        return;
      }

      this.hasError = false;

      // Submit tracking events
      window.parent.postMessage(
        JSON.stringify({
          action: "bookingForm_gtmTrackCustomEvent",
          eventData: {
            ga_event_name: "booking_form_submitted",
            item_id: this.form.id,
            item_name: this.form.formName,
            event_category: "booking form",
            event_label: "Booking form submitted",
            num_guests: this.numGuests,
            value: 100,
            currency: "DKK",
            // idm_venueId: this.venueId,
            // idm_venueName: this.venueName,
            // country: this.venueCountry,
          },
        }),
        "*"
      );

      // window.fbq('track', 'Purchase'); TODO add purchase event

      this.isSubmitting = false;

      // use router to redirect to form-submitted
      /* this.$router.push({
        name: "form-submitted",
        params: {
          referralUserId: this.referralUserId,
          venueId: this.venueId,
        }, 
      }); */
      this.$router.push("/form-submitted");
    },
    async submit() {
      this.isSubmitting = true;
      this.$refs.recaptcha.execute();
    },
  },
};
</script>

<style>
.grecaptcha-badge {
  visibility: hidden;
}
body {
  background-color: rgba(0, 0, 0, 0.6);
}

#booking-form {
  border-radius: 45px;
  background-color: white;
}
</style>
<style scoped>
@media (min-width: 768px) {
  .md\:visible {
    display: block;
  }
}

.btn-popup-close {
  top: 1.5rem;
  right: 1.5rem;
  position: absolute;
}
.btn-popup-close:hover {
  opacity: 0.75;
}
.btn-popup-close-relative img {
  vertical-align: middle;
  object-fit: cover;
  height: 30px;
  width: auto;
}

.btn-popup-close img {
  object-fit: cover;
  height: 30px;
  width: auto;
}

.totals {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.totals .vat {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
  color: theme("colors.muted");
}

.subscript {
  opacity: 0.6;
}
</style>