<template>
  <date-picker
    v-model="internalValue.answer"
    mode="date"
    :min-date="new Date()"
    :max-date="new Date(2028, 12, 31)"
    :model-config="modelConfig"
    :masks="{ title: 'MMM YYYY', input: 'DD/MMM/YYYY' }"
  >
    <!-- @dayclick="validate" -->
    <template v-slot="{ inputValue, inputEvents }">
      <div class="floating-label-input mt-2">
        <input
          :value="inputValue"
          v-on="inputEvents"
          class="peer focus:outline-none border-none p-0"
          placeholder="When"
        />
        <label for="date">{{ internalValue.label }}</label>
      </div>
    </template>
  </date-picker>
</template>

<script >
import { DatePicker } from "v-calendar";
export default {
  name: "FormDate",
  props: {
    modelValue: Object,
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
    };
  },
  emits: ["update:modelValue"],
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
  },
};
</script>
