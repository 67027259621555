<template>
  <div class="floating-label-input mt-2 h-32">
    <textarea
      v-model="internalValue.answer"
      class="peer focus:outline-none border-none p-0 textarea-field"
      :placeholder="internalValue.label"
    ></textarea>
    <label for="textarea" class="">{{ internalValue.label }}</label>
  </div>
</template>

<script >
export default {
  name: "FormTextarea",
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
  },
};
</script>

<style scoped>
label {
  padding-right: 2rem;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.textarea-field {
  resize: none;
  height: calc(100% - 1.7rem);
  padding-right: calc(2* 1.25rem);
  width: 100%;
}
</style>