import * as apiSubmitForm from '@/api/venue/submitForm'
import * as apiGetForm from '@/api/venue/getForm'

export const ACTIONS = { SubmitForm: 'SubmitForm', GetForm: 'GetForm', }
export const MUTATIONS = { GotForm: 'GotForm' }

export default {
    namespaced: true,
    state: {
        form: null
    },
    getters: {
        form: state => state.form,
    },
    mutations: {
        [MUTATIONS.GotForm](state, form) {
            state.form = form
        },
    },
    actions: {
        async [ACTIONS.GetForm]({commit}, formId) {
            const result = await apiGetForm.get(formId);

            if (result) {
                commit(MUTATIONS.GotForm, result)
                return result;
            }
        },
        async [ACTIONS.SubmitForm](_, dto) {
            const result = await apiSubmitForm.post(dto);

            if (result) {
                return result;
            }
        },
    }
};