import BookingFormSubmitted from '../src/views/BookingFormSubmitted.vue'
import BookingForm from '../src/views/BookingForm.vue'
import { createWebHistory, createRouter } from "vue-router";
import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import store from './store';
import { createI18n } from 'vue-i18n'
//import { setupAuth } from './auth';

//import { routeGuard } from '@/auth'

const routes = [
  {
    path: '/form/:formId/:venueId',
    name: 'Booking form',
    component: BookingForm,
    //beforeEnter: routeGuard
  },
  {
    path: '/form-submitted',
    name: 'Booking form submitted',
    component: BookingFormSubmitted,
    //beforeEnter: routeGuard
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const app = createApp(App);

/*function callbackRedirect(appState) {
  router.push(
      appState && appState.targetUrl
      ? appState.targetUrl
      : '/'
  );
}*/

/*const authConfig = {
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
  redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URI,
  audience: process.env.VUE_APP_AUTH_AUDIENCE
}*/

/*setupAuth(authConfig, callbackRedirect).then((auth) => {
  app.use(auth).mount('#app')
})*/


const messages = {
  en: {
    message: {
      successTitle: "We’ve recieved your booking request",
      successDescription: "We are looking forward to seeing you",
      errorMsg: "Something went wrong. Please try again.",
      loading: "Loading...",
      total: 'Total',
      tax: "incl. VAT",
      country: "Country",
      defaultCountryCode: "+44",
      currency: {
        GBP: "£",
        NOK: "kr",
        EUR: "€",
        DKK: "kr",
      },
      more: "more",
      less: "less",
      continue: "OK"
    }
  },
  "fi": {
    message: {
      successTitle: "Olemme vastaanottaneet varauspyyntösi",
      successDescription: "Toivotamme tervetulleeksi",
      errorMsg: "Jotain meni pieleen. Yritä uudelleen.",
      loading: "Ladataan...",
      total: 'Yhteensä',
      tax: 'sis. alv',
      country: "Maa",
      defaultCountryCode: "+358",
      currency: {
        GBP: "£",
        NOK: "kr",
        EUR: "€",
        DKK: "kr",
      },
      more: "lisää",
      less: "vähemmän",
      continue: "OK"
    }
  },
  "da": {
    message: {
      successTitle: "Vi har modtaget din bookingforespørgsel",
      successDescription: "Vi glæder os til at se dig",
      errorMsg: "Noget gik galt. Prøv igen.",
      loading: "Indlæser...",
      total: 'I alt',
      tax: "inkl. moms",
      country: "Land",
      defaultCountryCode: "+45",
      currency: {
        GBP: "£",
        NOK: "kr",
        EUR: "€",
        DKK: "kr",
      },
      more: "mere",
      less: "mindre",
      continue: "OK"
    }
  },
  no: {
    message: {
      successTitle: "Vi har mottatt din bookingforespørsel",
      successDescription: "Vi gleder oss til å se deg",
      errorMsg: "Noe gikk galt. Prøv igjen.",
      loading: "Laster...",
      total: 'I alt',
      tax: "inkl. mva",
      country: "Land",
      defaultCountryCode: "+47",
      currency: {
        GBP: "£",
        NOK: "kr",
        EUR: "€",
        DKK: "kr",
      },
      more: "mer",
      less: "mindre",
      continue: "OK"
    }
  },

}

const i18n = createI18n({
  //locale: 'fi-FI',
  locale: navigator.language || navigator.userLanguage,
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages

})


app.use(i18n)
app.use(router)
app.use(store)
app.mount('#app')