<template>
  <div class="floating-label-input mt-2">
    <Listbox v-model="internalValue.answer">
      <div
        class="relative floating-label-listbox"
        :class="{ 'placeholder-shown': !internalValue.answer || internalValue.answer === '' }"
      >
        <ListboxButton
          class="
            relative
            w-full
            text-left
            bg-white
            rounded
            h-7
            focus:outline-none
            px-0
          "
        >
          <span class="block truncate">{{ internalValue.answer }}</span>
          <span
            class="
              absolute
              inset-y-0
              right-0
              flex
              items-center
              pr-2
              pointer-events-none
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-4 w-4 mr-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </span>
        </ListboxButton>

        <transition
          leave-active-class="transition duration-100 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="
              absolute
              w-full
              py-1
              mt-1
              overflow-auto
              text-base
              bg-white
              rounded-md
              shadow-lg
              max-h-60
              ring-1 ring-black ring-opacity-5
              focus:outline-none
              sm:text-sm
              z-10
            "
          >
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="tag in internalValue.availableOptions"
              :key="tag"
              :value="tag"
              as="template"
            >
              <li
                :class="[
                  active ? 'text-amber-900 bg-amber-100' : 'text-gray-900',
                  'cursor-pointer select-none relative py-2 pl-10 pr-4',
                ]"
              >
                <span
                  :class="[
                    selected ? 'font-medium' : 'font-normal',
                    'block truncate',
                  ]"
                  >{{ tag }}</span
                >
                <span
                  v-if="selected"
                  class="
                    absolute
                    inset-y-0
                    left-0
                    flex
                    items-center
                    pl-3
                    text-amber-600
                  "
                >
                  <CheckIcon class="w-5 h-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>

    <label for="select" class="">{{internalValue.label}}</label>
  </div>
</template>

<script >
import { CheckIcon } from "@heroicons/vue/solid";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  //DialogTitle,
} from "@headlessui/vue";
import "v-calendar/dist/style.css";

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    CheckIcon,
  },
  name: "FormDropdown",
  props: {
    modelValue: Object,
  },
  emits: ["update:modelValue"],
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
  },
};
</script>
