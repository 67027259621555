
<template>
  <div class="md:px-8 mt-6">
    <h1 class="page-sub-header">
      {{ internalValue.label }}
    </h1>
    <div class="max-w-xl mx-auto mt-3">
      <div class="flex flex-col text-left mt-1" v-if="true">
        <div class="mb-5">
          <div class="my-auto">
            <!--    <p
              class="min-spend-message"
              v-if="minSpendValue && minSpendValue > 0"
            >
              You have chosen a table that requires
              {{ formatPrice(minSpendValue) }} currencytodo of minimum spend
            </p> -->
            <p class="min-spend-message">
              {{ internalValue.helpText }}
            </p>
          </div>
        </div>
        <div
          v-for="(item, index) in internalValue.products"
          :key="item.description"
          class="product-item"
        >
          <div class="flex">
            <div class="flex w-full">
              <img
                class="product-image"
                style="object-fit: cover"
                :src="item.imageUrl"
              />
              <div class="w-full">
                <h6>{{ item.name }}</h6>
                <p
                  class="description mt-1"
                  @click="onShowMore(item.description, index)"
                >
                  {{ formatDecription(item.description, index) }}
                  <b
                    v-if="showMore != index && item.description.length > 59"
                    class="cursor-pointer"
                    >{{ $t("message.more")}}</b
                  >
                </p>
                <b
                  class="description cursor-pointer"
                  @click="onShowMore(item.description, index)"
                  v-if="showMore == index"
                  >{{ $t("message.less")}}</b
                >

                <div class="flex flex-row">
                  <p
                    v-if="item.beforePrice && item.beforePrice > 0"
                    class="price text-muted line-through mr-2"
                  >
                    {{ item.beforePrice * numGuests }}
                    {{ $t("message.currency." + currency) }}
                  </p>
                  <p class="price">
                    {{ item.price * numGuests }}
                    {{ $t("message.currency." + currency) }}
                  </p>

                  <div
                    v-if="item.quantity == 0"
                    class="mt-3 ml-auto opacity-60"
                  >
                    <button
                      @click="selectSingleProduct(index)"
                      class="add-button blue"
                    >
                      <svg
                        class="mx-auto"
                        width="24"
                        height="20"
                        viewBox="0 0 24 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.7527 6.87415L19.3029 17.5389C19.2372 18.0769 18.9793 18.5722 18.5784 18.9295C18.1775 19.2869 17.6615 19.4821 17.1284 19.478H6.88603C6.35289 19.4821 5.83687 19.2869 5.43595 18.9295C5.03504 18.5722 4.77719 18.0769 4.71144 17.5389L3.26172 6.87415"
                          stroke="white"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M0.333984 6.87424H23.6673"
                          stroke="white"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M5.69727 6.87424L8.39376 1.16217"
                          stroke="white"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M18.2947 6.87424L15.6055 1.16217"
                          stroke="white"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M8.98047 9.69708V16.5295"
                          stroke="white"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M11.9961 9.69708V16.5295"
                          stroke="white"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M15.0117 9.69708V16.5295"
                          stroke="white"
                          stroke-miterlimit="10"
                        />
                      </svg>
                    </button>
                  </div>
                  <div v-if="item.quantity > 0" class="mt-3 ml-auto">
                    <button
                      style="cursor: default"
                      class="selected-product blue"
                    >
                      <svg
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 17.837 17.837"
                        style="
                          display: block;
                          margin: auto;
                          height: 1.2rem;
                          width: auto;
                        "
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            style="fill: #fff"
                            d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27
		c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0
		L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
                          />
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  name: "FormProducts",
  components: {},
  props: {
    modelValue: Object,
    currency: String,
    numGuests: Number,
  },
  data: () => ({
    showMore: null,
  }),
  mounted() {
    // if no products are selected, select the first one
    if (this.internalValue.products.length > 0) {
      if (this.internalValue.products[0].quantity == 0) {
        this.selectSingleProduct(0);
      }
    }
  },
  emits: ["update:modelValue"],
  methods: {
    formatDecription(description, index) {
      return description.length > 59 && index != this.showMore
        ? description.substr(0, 60) + "..."
        : description;
    },
    onShowMore(description, index) {
      if (description.length > 59) {
        this.showMore == index
          ? (this.showMore = null)
          : (this.showMore = index);
      }
    },
    selectSingleProduct(idx) {
      this.$emit("update:modelValue", {
        ...this.modelValue,
        products: this.modelValue.products.map((item, index) => {
          if (index == idx) {
            return {
              ...item,
              quantity: 1,
              minimize: true,
            };
          } else {
            return {
              ...item,
              quantity: 0,
              minimize: false,
            };
          }
        }),
      });
    },
  },
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(v) {
        this.$emit("update:modelValue", v);
      },
    },
  },
};
</script>

<style scoped>
.subscript {
  opacity: 0.6;
}
.product-item .product-image {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin-right: 12px;
}

.product-item h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.product-item .description {
  @apply text-muted leading-tight;
  font-size: 14px;
}

.product-item .price {
  @apply mt-8;
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
}

.product-item hr {
  margin-top: 16px;
  margin-bottom: 16px;
  border-color: theme("colors.grey.200");
}

.totals {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.totals .vat {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: center;
  color: theme("colors.muted");
}

.min-spend-message {
  @apply text-muted leading-tight;
  font-size: 14px;
}

.selected-product {
  @apply rounded-full cursor-pointer bg-primary text-white;
  width: 40px;
  height: 40px;
}

.selected-product.blue {
  @apply bg-blue;
}

.add-button {
  @apply rounded-full cursor-pointer bg-primary hover:opacity-100 text-white;
  width: 40px;
  height: 40px;
}

.add-button.blue {
  @apply bg-blue;
}

.skip-button {
  @apply cursor-pointer hover:opacity-70;

  margin-top: 20px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}
</style>
