<template>
  <div class="flex items-center justify-center h-screen">
    <div class="text-center">
      <svg
        width="72"
        height="70"
        viewBox="0 0 72 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="mx-auto"
      >
        <path
          d="M71.0983 12.7267L67.5616 9.19003L31.6783 45.0733L15.8549 29.2433L12.3216 32.7767L31.6783 52.1366L61.6283 22.1834C64.8321 28.7275 65.5004 36.2259 63.5043 43.2335C61.5083 50.2411 56.9892 56.2619 50.818 60.1357C44.6467 64.0094 37.2601 65.462 30.0816 64.2135C22.903 62.9649 16.4407 59.1035 11.9398 53.3735C7.43894 47.6436 5.21815 40.4505 5.70542 33.1805C6.19268 25.9105 9.35353 19.0781 14.5788 14C19.8041 8.92192 26.7239 5.9575 34.0048 5.67807C41.2858 5.39864 48.4126 7.82398 54.0117 12.4867L57.2149 8.64666C50.6146 3.14556 42.1985 0.306422 33.6147 0.685231C25.0308 1.06404 16.8975 4.63355 10.8075 10.6947C4.71742 16.7558 1.10931 24.872 0.689631 33.4539C0.269953 42.0359 3.06899 50.4654 8.53859 57.0918C14.0082 63.7182 21.7544 68.0643 30.2604 69.2788C38.7663 70.4934 47.4192 68.4889 54.5248 63.658C61.6303 58.8272 66.6766 51.5179 68.6756 43.1615C70.6745 34.805 69.482 26.0033 65.3316 18.48L71.0983 12.7267Z"
          fill="white"
        />
      </svg>

      <div class="p-4 md:p-6 sm:max-w-screen-sm md:max-w-screen-sm">
        <h1 class="text-xl text-white" style="word-break: break-whitespace">
          {{ form ? form.submittedTitle : $t("message.successTitle") }}
        </h1>
        <!--   -->
        <p class="text-gray-200 text-sm max-w-sm mx-auto pt-2">
          {{ form ? form.submittedText : $t("message.successDescription") }}
        </p>

        <button @click="closeForm(false)" class="btn-cot mt-8">
          {{ $t("message.continue") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      form: "form/form",
    }),
  },
  methods: {
    closeForm() {
      window.parent.postMessage(JSON.stringify({action: "closeBookingForm"}), "*");
    },
  },
};
</script>
<style>
.line-clamp {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 0.14em;
}
</style>