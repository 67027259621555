import * as apiGetLegalDocument from '@/api/venue/getLegalDocument'

export const ACTIONS = { GetLegalDocument: 'GetLegalDocument', SetConsent: 'SetConsent', SetActiveDocument: 'SetActiveDocument' }
export const MUTATIONS = { GotLegalDocument: "GotLegalDocument", GotVenueName: "GotVenueName", GotVenueCountry: "GotVenueCountry", GotAnalyticsDetails: "GotAnalyticsDetails"}

export default {
    namespaced: true,
    state: {
        legalDocuments: [],
        venueName: "",
        venueCountry: "",
        analyticsDetails: null,
    },
    getters: {
        venueName: state => state.venueName,
        venueCountry: state => state.venueCountry,
        legalDocuments: state => {
            return state.legalDocuments
        },
        legalDocument: state => (id) => {
            return state.legalDocuments.find(e => e.id === id);
        },
        analyticsDetails: state => state.analyticsDetails,
    },
    mutations: {
        [MUTATIONS.GotLegalDocument](state, wrapper) {
            state.legalDocuments.push(wrapper);
        },
        [MUTATIONS.GotVenueName](state, name) {
            state.venueName = name;
        },
        [MUTATIONS.GotVenueCountry](state, country) {
            state.venueCountry = country;
        },
        [MUTATIONS.GotAnalyticsDetails](state, details) {
            state.analyticsDetails = details;
        }
    },
    actions: {
        async [ACTIONS.GetLegalDocument]({ commit }, { legalDocumentId, venueId }) {
            const result = await apiGetLegalDocument.get({ legalDocumentId: legalDocumentId, venueId: venueId });

            if (result) {
                commit(MUTATIONS.GotVenueName, result.venueName)
                commit(MUTATIONS.GotVenueCountry, result.venueCountry)
                commit(MUTATIONS.GotLegalDocument, result.doc)
                commit(MUTATIONS.GotAnalyticsDetails, result.analyticsDetails)
                return result;
            }
        },
    }
};