<template>
  <div class="flex flex-col justify-center items-center mt-12 pt-12">
    <img
      class="animate-flicker rekom-loading-logo"
      src="https://rekomlifestorageprod.blob.core.windows.net/crm/rekom_logo_clean.svg"
    />
    <span class="mt-2">{{ text ? text : "Loading" }}</span>
  </div>
</template>

<script>
export default {
  props: ["text"],
};
</script>

<style scoped>
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 1s infinite;
  -moz-animation: flickerAnimation 1s infinite;
  -o-animation: flickerAnimation 1s infinite;
  animation: flickerAnimation 1s infinite;
}

.rekom-loading-logo {
  width: 45px;
  height: auto;
}
</style>