import apiClient from '@/services/axios'

export async function get(formId) {
    const url = `/BookingForms/View/${formId}?apiKey=${process.env.VUE_APP_CRM_API_KEY}`
    var response = await apiClient.get(url);

    if (response) {
        console.log(response)
        return response.data;
    }

    throw new Error('Unable to get form')
}