import { createStore } from "vuex"


import legalDocuments from './legalDocuments'
import form from './form'

const store = createStore({
   state: {
      name: "Booking"
   },
   modules: {
      legalDocuments,
      form
   }
})

export default store